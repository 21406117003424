
import { defineComponent } from "vue";
import LayoutIcon from "@/shared/components/Icons/LayoutIcon.vue";
import registerBannerSrc from "@/assets/layout/register/register-banner.png";

export default defineComponent({
  setup() {
    return {
      registerBannerSrc,
    };
  },
  components: { LayoutIcon },
});
