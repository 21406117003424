import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "register-page__container pb-24" }
const _hoisted_2 = { class: "my-14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegisterBackground = _resolveComponent("RegisterBackground")!
  const _component_BrandIcon = _resolveComponent("BrandIcon")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_GoogleSignIn = _resolveComponent("GoogleSignIn")!
  const _component_TextDivider = _resolveComponent("TextDivider")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, { class: "register-page" }, {
    complexBackground: _withCtx(() => [
      _createVNode(_component_RegisterBackground)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BrandIcon, { class: "mt-8 w-48 h-auto" }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_typography_title, {
            level: 2,
            class: "mb-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Register")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_typography_title, {
            level: 5,
            class: "mt-0"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Start now!")), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_GoogleSignIn, {
          class: "w-full",
          onClick: _ctx.authenticateUserViaGoogle
        }, null, 8, ["onClick"]),
        _createVNode(_component_TextDivider, {
          text: _ctx.t('OR'),
          class: "my-8"
        }, null, 8, ["text"]),
        _createVNode(_component_FormGroup, {
          model: _ctx.formState,
          loading: _ctx.loginLoading,
          onFinish: _ctx.authenticateUser,
          class: "register-page__form mb-4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              name: "email",
              value: _ctx.formState.email,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.email) = $event)),
              label: _ctx.t('Email Address'),
              placeholder: _ctx.t('username@domain.com'),
              rules: [
            _ctx.makeRequiredRule(_ctx.t('This field is required.')),
            _ctx.makeEmailRule(_ctx.t('Please enter a valid email address.')),
          ]
            }, null, 8, ["value", "label", "placeholder", "rules"]),
            _createVNode(_component_InputText, {
              name: "fullName",
              value: _ctx.formState.fullName,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.fullName) = $event)),
              label: _ctx.t('Full Name'),
              placeholder: _ctx.t('Your name here'),
              rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
            }, null, 8, ["value", "label", "placeholder", "rules"]),
            _createVNode(_component_InputPassword, {
              name: "password",
              value: _ctx.formState.password,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.password) = $event)),
              label: _ctx.t('Password'),
              placeholder: _ctx.t('Your password'),
              rules: [
            _ctx.makeRequiredRule(_ctx.t('This field is required.')),
            _ctx.makePasswordRequirementsRule(),
          ]
            }, null, 8, ["value", "label", "placeholder", "rules"]),
            _createVNode(_component_InputPassword, {
              name: "confirmPassword",
              value: _ctx.formState.confirmPassword,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.confirmPassword) = $event)),
              label: _ctx.t('Confirm Password'),
              placeholder: _ctx.t('Re-enter your password'),
              rules: [
            _ctx.makeRequiredRule(_ctx.t('This field is required.')),
            {
              required: true,
              type: 'string',
              validator: _ctx.passwordRequirementsValidator,
            },
          ]
            }, null, 8, ["value", "label", "placeholder", "rules"]),
            _createVNode(_component_a_button, {
              type: "primary",
              "html-type": "submit",
              loading: _ctx.loginLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Sign Up")), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ]),
          _: 1
        }, 8, ["model", "loading", "onFinish"]),
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Already signed up?")) + " ", 1),
            _createVNode(_component_a_button, {
              type: "link",
              onClick: _ctx.redirectToLogin,
              class: "tracking-normal px-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("GO TO LOGIN.")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}