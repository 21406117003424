import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "register-page-background complex-bg" }
const _hoisted_2 = { class: "complex-bg__icons" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        id: "registerBanner",
        src: _ctx.registerBannerSrc
      }, null, 8, _hoisted_3),
      _createVNode(_component_LayoutIcon, {
        id: "brushBlue",
        name: "brushBlue"
      }),
      _createVNode(_component_LayoutIcon, {
        id: "brushPink",
        name: "brushPink"
      })
    ])
  ]))
}