
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputPassword from "@/shared/components/Forms/InputPassword.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import GoogleSignIn from "@/shared/components/GoogleSignIn.vue";
import BrandIcon from "@/shared/components/Icons/BrandIcon.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import TextDivider from "@/shared/components/TextDivider.vue";
import { useAuthentication } from "@/shared/composables/useAuthentication";
import {
  makeEmailRule,
  makePasswordRequirementsRule,
  makeRequiredRule,
} from "@/shared/utils/validators/commonValidators";
import routeNames from "@/web/router/routeNames";
import RegisterBackground from "@/web/views/Register/RegisterBackground.vue";
import { Rule } from "ant-design-vue/lib/form";
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const { handleLogin, loginLoading } = useAuthentication();

    const formState = reactive({
      email: "",
      fullName: "",
      password: "",
      confirmPassword: "",
    });

    const authenticateUserViaGoogle = async () => {
      await handleLogin();

      // Handle Redirect
      if (route.query.redirectTo) {
        router.push(String(route.query.redirectTo ?? ""));
      } else {
        router.push({ name: routeNames.home });
      }
    };

    const authenticateUser = async () => {
      try {
        await handleLogin({
          method: "email-password",
          authenticateViaEmailInput: {
            email: formState.email,
            password: formState.password,
            fullName: formState.fullName,
            isRegistration: true,
          },
        });

        if (route.query.redirectTo) {
          router.push({ path: String(route.query.redirectTo) });
        } else {
          router.push({ name: routeNames.home });
        }
      } catch (e) {
        console.log("Email Password Register error", e);
      }
    };

    const passwordRequirementsValidator = async (
      _rule: Rule,
      value: string
    ) => {
      if (value !== formState.password) {
        return Promise.reject("Password does not match.");
      }

      return Promise.resolve();
    };

    return {
      t,
      formState,
      makeRequiredRule,
      makeEmailRule,
      makePasswordRequirementsRule,
      passwordRequirementsValidator,
      redirectToLogin: () => {
        router.push({ name: routeNames.login });
      },
      authenticateUser,
      loginLoading,
      authenticateUserViaGoogle,
    };
  },
  components: {
    PageLayout,
    BrandIcon,
    TextDivider,
    GoogleSignIn,
    InputText,
    InputPassword,
    FormGroup,
    RegisterBackground,
  },
});
